<!--  -->
<template>
  <div>
    <div
      class="home-declare mb-20 width-wrap back-white"
      style="margin-top: 10px"
    >
      <div class="el-row" style="margin-left: -10px; margin-right: -10px">
        <div
          class="el-col el-col-24"
          style="padding-left: 10px; padding-right: 10px"
        >
          <div
            class="
              zq-box-header
              border-b-big-black
              position-relative
              flex-between
              zq-box-header--middle
            "
          >
            <span class="zq-box-header-title font-w-6">项目申报</span>
            <span></span>
            <!-- <span class="zq-box-header-right cursor-pointer">更多 &gt;</span> -->
          </div>
          <div class="home-declare-list" v-loading="dataLoading">
            <div class="el-row" style="margin-left: -10px; margin-right: -10px">
              <div
                class="theme-type-card el-col el-col-6"
                style="padding-left: 10px; padding-right: 10px"
                v-for="(item, index) in leftshowList"
                :key="index"
                @click="toPolicyGuide(item)"
              >
                <div
                  class="theme-type-item"
                  @mouseover="leftMouseover"
                  @mouseleave="leftMouseleave"
                  ref="leftCard"
                  :id="'leftCard' + index"
                >
                  <div class="item-main flex-left">
                    <span
                      :title="item.name"
                      class="title ellipsis-line-2 fz-16 font-w-5"
                      ref="leftCardTitle"
                      style="font-size: 18px"
                    >
                      {{ item.name }}
                    </span>
                  </div>
                  <div class="item-status flex-left">
                    <div class="count-wrap">
                      <span class="time" ref="leftCardTime">
                        {{ item.limit }}
                      </span>
                    </div>
                    <div class="fc-money-limit ellipsis" ref="leftCardLimit">
                      <span>资金支持</span>
                    </div>
                  </div>
                  <div class="flex-between text" ref="leftCardText">
                    <span :title="item.dept_name" class="ellipsis">{{
                      item.dept_name
                    }}</span>
                    <!-- <span>1833人看过</span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!---->
        </div>
        <!-- <div
          class="el-col el-col-6"
          style="padding-left: 10px; padding-right: 10px"
        >
          <div
            class="
              zq-box-header
              border-b-big-black
              position-relative
              flex-between
              zq-box-header--middle
            "
          >
            <span class="zq-box-header-title font-w-6">智能推荐</span>
            <span></span>
            <div>
              <span class="zq-box-header-more"
                ><img
                  src="//res.oss.zqlian.com/4406/0001/0002/0004/hyp.png?version=5.6.0"
                  alt=""
                  width="14"
                  class="mr-5"
                />换一批</span
              >
            </div>
          </div>
          <div class="home-declare-list flex-left flex-wrap" v-loading="dataLoading">
            <div class="width-100 el-row">
              <div
                class="theme-type-card el-col el-col-24"
                v-for="(item, index) in rightshowList"
                :key="index"
                @click="toPolicyGuide(item)"
              >
                <div
                  class="theme-type-item"
                  @mouseover="rightMouseover"
                  @mouseleave="rightMouseleave"
                  ref="rightCard"
                  :id="'rightCard' + index"
                >
                  <div class="item-main flex-left">
                    <span
                      :title="item.name"
                      class="title ellipsis-line-2 fz-16 font-w-5"
                      ref="rightCardTitle"
                    >
                      {{item.name}}
                    </span>
                  </div>
                  <div class="item-status flex-left">
                    <span class="time-content-easy">
                      <span class="time" ref="rightCardTime">
                       {{item.limit}}
                      </span>
                    </span>
                    <div class="fc-money-limit ellipsis" ref="rightCardLimit">
                      <span>资金支持</span>
                    </div>
                  </div>
                  <div class="flex-between text" ref="rightCardText">
                    <span :title="item.dept_name" class="ellipsis"
                      >{{item.dept_name}}</span
                    >
                    <span>703人看过</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
const NOW = new Date();
export default {
  data() {
    return {
      showList: [], //渲染总数据
      dataLoading: false,
      leftshowList: [], //左侧渲染数据
      rightshowList: [], //右侧侧渲染数据
    };
  },

  components: {},

  created() {},
  mounted() {
    this.getListData();
  },
  methods: {
    //   左侧鼠标移入
    leftMouseover(e) {
      let leftCard = this.$refs.leftCard;
      let leftCardTime = this.$refs.leftCardTime;
      let leftCardTitle = this.$refs.leftCardTitle;
      let leftCardText = this.$refs.leftCardText;
      let leftCardLimit = this.$refs.leftCardLimit;
      let targetid = e.currentTarget.id;
      switch (targetid) {
        case "leftCard0":
          leftCard[0].style.backgroundColor = "#4795f1";
          leftCard[0].style.boxShadow = "unset";
          leftCardTime[0].style.color = "#fff";
          leftCardTime[0].style.border = "0.0625rem solid #fff";
          leftCardTitle[0].style.color = "#fff";
          leftCardText[0].style.color = "#fff";
          leftCardLimit[0].style.color = "#fff";
          leftCardLimit[0].style.border = "0.0625rem solid #fff";
          break;
        case "leftCard1":
          leftCard[1].style.backgroundColor = "#4795f1";
          leftCard[1].style.boxShadow = "unset";
          leftCardTime[1].style.color = "#fff";
          leftCardTime[1].style.border = "0.0625rem solid #fff";
          leftCardTitle[1].style.color = "#fff";
          leftCardText[1].style.color = "#fff";
          leftCardLimit[1].style.color = "#fff";
          leftCardLimit[1].style.border = "0.0625rem solid #fff";
          break;
        case "leftCard2":
          leftCard[2].style.backgroundColor = "#4795f1";
          leftCard[2].style.boxShadow = "unset";
          leftCardTime[2].style.color = "#fff";
          leftCardTime[2].style.border = "0.0625rem solid #fff";
          leftCardTitle[2].style.color = "#fff";
          leftCardText[2].style.color = "#fff";
          leftCardLimit[2].style.color = "#fff";
          leftCardLimit[2].style.border = "0.0625rem solid #fff";
          break;
        case "leftCard3":
          leftCard[3].style.backgroundColor = "#4795f1";
          leftCard[3].style.boxShadow = "unset";
          leftCardTime[3].style.color = "#fff";
          leftCardTime[3].style.border = "0.0625rem solid #fff";
          leftCardTitle[3].style.color = "#fff";
          leftCardText[3].style.color = "#fff";
          leftCardLimit[3].style.color = "#fff";
          leftCardLimit[3].style.border = "0.0625rem solid #fff";
          break;
        case "leftCard4":
          leftCard[4].style.backgroundColor = "#4795f1";
          leftCard[4].style.boxShadow = "unset";
          leftCardTime[4].style.color = "#fff";
          leftCardTime[4].style.border = "0.0625rem solid #fff";
          leftCardTitle[4].style.color = "#fff";
          leftCardText[4].style.color = "#fff";
          leftCardLimit[4].style.color = "#fff";
          leftCardLimit[4].style.border = "0.0625rem solid #fff";
          break;
        case "leftCard5":
          leftCard[5].style.backgroundColor = "#4795f1";
          leftCard[5].style.boxShadow = "unset";
          leftCardTime[5].style.color = "#fff";
          leftCardTime[5].style.border = "0.0625rem solid #fff";
          leftCardTitle[5].style.color = "#fff";
          leftCardText[5].style.color = "#fff";
          leftCardLimit[5].style.color = "#fff";
          leftCardLimit[5].style.border = "0.0625rem solid #fff";
          break;
           case "leftCard6":
          leftCard[6].style.backgroundColor = "#4795f1";
          leftCard[6].style.boxShadow = "unset";
          leftCardTime[6].style.color = "#fff";
          leftCardTime[6].style.border = "0.0625rem solid #fff";
          leftCardTitle[6].style.color = "#fff";
          leftCardText[6].style.color = "#fff";
          leftCardLimit[6].style.color = "#fff";
          leftCardLimit[6].style.border = "0.0625rem solid #fff";
          break;
           case "leftCard7":
          leftCard[7].style.backgroundColor = "#4795f1";
          leftCard[7].style.boxShadow = "unset";
          leftCardTime[7].style.color = "#fff";
          leftCardTime[7].style.border = "0.0625rem solid #fff";
          leftCardTitle[7].style.color = "#fff";
          leftCardText[7].style.color = "#fff";
          leftCardLimit[7].style.color = "#fff";
          leftCardLimit[7].style.border = "0.0625rem solid #fff";
          break;
        default:
          break;
      }
    },
    // 左侧鼠标移出
    leftMouseleave(e) {
      let leftCard = this.$refs.leftCard;
      let leftCardTime = this.$refs.leftCardTime;
      let leftCardTitle = this.$refs.leftCardTitle;
      let leftCardText = this.$refs.leftCardText;
      let leftCardLimit = this.$refs.leftCardLimit;
      let targetid = e.currentTarget.id;
      switch (targetid) {
        case "leftCard0":
          leftCard[0].style.backgroundColor = "#fff";
          leftCard[0].style.boxShadow =
            "0 0.125rem 0.5625rem 0.125rem rgba(225,222,222,.5)";
          leftCardTime[0].style.color = "#fd3938";
          leftCardTime[0].style.border = "0.0625rem solid #fd3938";
          leftCardTitle[0].style.color = "#000";
          leftCardText[0].style.color = "#9aa0a3";
          leftCardLimit[0].style.color = "#2263e1";
          leftCardLimit[0].style.border = "0.0625rem solid #2263e1";
          break;
        case "leftCard1":
          leftCard[1].style.backgroundColor = "#fff";
          leftCard[1].style.boxShadow =
            "0 0.125rem 0.5625rem 0.125rem rgba(225,222,222,.5)";
          leftCardTime[1].style.color = "#fd3938";
          leftCardTime[1].style.border = "0.0625rem solid #fd3938";
          leftCardTitle[1].style.color = "#000";
          leftCardText[1].style.color = "#9aa0a3";
          leftCardLimit[1].style.color = "#2263e1";
          leftCardLimit[1].style.border = "0.0625rem solid #2263e1";
          break;
        case "leftCard2":
          leftCard[2].style.backgroundColor = "#fff";
          leftCard[2].style.boxShadow =
            "0 0.125rem 0.5625rem 0.125rem rgba(225,222,222,.5)";
          leftCardTime[2].style.color = "#fd3938";
          leftCardTime[2].style.border = "0.0625rem solid #fd3938";
          leftCardTitle[2].style.color = "#000";
          leftCardText[2].style.color = "#9aa0a3";
          leftCardLimit[2].style.color = "#2263e1";
          leftCardLimit[2].style.border = "0.0625rem solid #2263e1";
          break;
        case "leftCard3":
          leftCard[3].style.backgroundColor = "#fff";
          leftCard[3].style.boxShadow =
            "0 0.125rem 0.5625rem 0.125rem rgba(225,222,222,.5)";
          leftCardTime[3].style.color = "#fd3938";
          leftCardTime[3].style.border = "0.0625rem solid #fd3938";
          leftCardTitle[3].style.color = "#000";
          leftCardText[3].style.color = "#9aa0a3";
          leftCardLimit[3].style.color = "#2263e1";
          leftCardLimit[3].style.border = "0.0625rem solid #2263e1";
          break;
        case "leftCard4":
          leftCard[4].style.backgroundColor = "#fff";
          leftCard[4].style.boxShadow =
            "0 0.125rem 0.5625rem 0.125rem rgba(225,222,222,.5)";
          leftCardTime[4].style.color = "#fd3938";
          leftCardTime[4].style.border = "0.0625rem solid #fd3938";
          leftCardTitle[4].style.color = "#000";
          leftCardText[4].style.color = "#9aa0a3";
          leftCardLimit[4].style.color = "#2263e1";
          leftCardLimit[4].style.border = "0.0625rem solid #2263e1";
          break;
        case "leftCard5":
          leftCard[5].style.backgroundColor = "#fff";
          leftCard[5].style.boxShadow =
            "0 0.125rem 0.5625rem 0.125rem rgba(225,222,222,.5)";
          leftCardTime[5].style.color = "#fd3938";
          leftCardTime[5].style.border = "0.0625rem solid #fd3938";
          leftCardTitle[5].style.color = "#000";
          leftCardText[5].style.color = "#9aa0a3";
          leftCardLimit[5].style.color = "#2263e1";
          leftCardLimit[5].style.border = "0.0625rem solid #2263e1";
          break;
          case "leftCard6":
          leftCard[6].style.backgroundColor = "#fff";
          leftCard[6].style.boxShadow =
            "0 0.125rem 0.5625rem 0.125rem rgba(225,222,222,.5)";
          leftCardTime[6].style.color = "#fd3938";
          leftCardTime[6].style.border = "0.0625rem solid #fd3938";
          leftCardTitle[6].style.color = "#000";
          leftCardText[6].style.color = "#9aa0a3";
          leftCardLimit[6].style.color = "#2263e1";
          leftCardLimit[6].style.border = "0.0625rem solid #2263e1";
          break;
          case "leftCard7":
          leftCard[7].style.backgroundColor = "#fff";
          leftCard[7].style.boxShadow =
            "0 0.125rem 0.5625rem 0.125rem rgba(225,222,222,.5)";
          leftCardTime[7].style.color = "#fd3938";
          leftCardTime[7].style.border = "0.0625rem solid #fd3938";
          leftCardTitle[7].style.color = "#000";
          leftCardText[7].style.color = "#9aa0a3";
          leftCardLimit[7].style.color = "#2263e1";
          leftCardLimit[7].style.border = "0.0625rem solid #2263e1";
          break;
        default:
          break;
      }
    },
    //   右侧鼠标移入
    rightMouseover(e) {
      let rightCard = this.$refs.rightCard;
      let rightCardTime = this.$refs.rightCardTime;
      let rightCardTitle = this.$refs.rightCardTitle;
      let rightCardText = this.$refs.rightCardText;
      let rightCardLimit = this.$refs.rightCardLimit;
      let targetid = e.currentTarget.id;
      switch (targetid) {
        case "rightCard0":
          rightCard[0].style.backgroundColor = "#4795f1";
          rightCard[0].style.boxShadow = "unset";
          rightCardTime[0].style.color = "#fff";
          rightCardTime[0].style.border = "0.0625rem solid #fff";
          rightCardTitle[0].style.color = "#fff";
          rightCardText[0].style.color = "#fff";
          rightCardLimit[0].style.color = "#fff";
          rightCardLimit[0].style.border = "0.0625rem solid #fff";
          break;
        case "rightCard1":
          rightCard[1].style.backgroundColor = "#4795f1";
          rightCard[1].style.boxShadow = "unset";
          rightCardTime[1].style.color = "#fff";
          rightCardTime[1].style.border = "0.0625rem solid #fff";
          rightCardTitle[1].style.color = "#fff";
          rightCardText[1].style.color = "#fff";
          rightCardLimit[1].style.color = "#fff";
          rightCardLimit[1].style.border = "0.0625rem solid #fff";
          break;

        default:
          break;
      }
    },
    // 右侧鼠标移出
    rightMouseleave(e) {
      let rightCard = this.$refs.rightCard;
      let rightCardTime = this.$refs.rightCardTime;
      let rightCardTitle = this.$refs.rightCardTitle;
      let rightCardText = this.$refs.rightCardText;
      let rightCardLimit = this.$refs.rightCardLimit;
      let targetid = e.currentTarget.id;
      switch (targetid) {
        case "rightCard0":
          rightCard[0].style.backgroundColor = "#fff";
          rightCard[0].style.boxShadow =
            "0 0.125rem 0.5625rem 0.125rem rgba(225,222,222,.5)";
          rightCardTime[0].style.color = "#fd3938";
          rightCardTime[0].style.border = "0.0625rem solid #fd3938";
          rightCardTitle[0].style.color = "#000";
          rightCardText[0].style.color = "#9aa0a3";
          rightCardLimit[0].style.color = "#2263e1";
          rightCardLimit[0].style.border = "0.0625rem solid #2263e1";
          break;
        case "rightCard1":
          rightCard[1].style.backgroundColor = "#fff";
          rightCard[1].style.boxShadow =
            "0 0.125rem 0.5625rem 0.125rem rgba(225,222,222,.5)";
          rightCardTime[1].style.color = "#fd3938";
          rightCardTime[1].style.border = "0.0625rem solid #fd3938";
          rightCardTitle[1].style.color = "#000";
          rightCardText[1].style.color = "#9aa0a3";
          rightCardLimit[1].style.color = "#2263e1";
          rightCardLimit[1].style.border = "0.0625rem solid #2263e1";
          break;

        default:
          break;
      }
    },
    toPolicyGuide(item) {
      // console.log('toPolicyGuide:', row, column)
      // 如果点击的是 政策项目 一列，办事指南中的 资助标准 显示所有项
      // 否，则传入qxId，办事指南中的资助标准显示对应的一项
      this.$store.commit("updateCurrentGuideId", item.id);
      let query = {
        id: item.id,
        name: item.name,
      };
      // column.property !== 'name' && (query.qxId = row.qxid)
      const { href } = this.$router.resolve({
        path: "/guideSuqiu",
        query: query,
      });
      window.open(href, "_blank");
    },

    getListData() {
      let url =
        "/dev-api/cfgpermitconfig/query/data/find/cfg_permitinfo_andzchx_list";
      let params = {
        itemtype: "2",
        release: "1",
        ["PAGE_CUR"]: "1",
        ["PAGE_ROWS"]: "",
      };
      let that = this;
      // 特殊处理一下 奖励金额
      switch (params.temp_amount) {
        case "0":
          params.zzxeleft = 1000;
          break;
        case "1":
          params["zzxe_500_1000"] = "1";
          break;
        case "2":
          params["zzxe_100_500"] = "1";
          break;
        case "3":
          params.zzxeright = 100;
          break;
        case "4":
          params.zzxeleft = Number(params.zzxeleft);
          params.zzxeright = Number(params.zzxeright);
          break;
        default:
          break;
      }
      Reflect.deleteProperty(params, "temp_amount");

      this.dataLoading = true;
      this.$httpApi
        .post(url, params)
        .then((res) => {
          that.dataLoading = false;
          that.showList = res.data;
          that.showList.forEach((element) => {
            let limit = that.getlimitTime(element.start_time, element.endtime);
            element["limit"] = limit;
          });
          that.leftshowList = that.showList.slice(0, 8);
          that.rightshowList = that.showList.slice(-2);
        })
        .catch((err) => {
          console.log("getListData error:", err);
          this.dataLoading = false;
        });
    },
    getlimitTime(start, end) {
      // 区分几种情况
      //     未开始/剩余天数/已过期
      let limitTime = "";
      let startTime = new Date(start);
      if (NOW < startTime) return (limitTime = "未开始");

      let endTime = new Date(end);
      if (endTime < NOW) return (limitTime = "已过期");
      let time = endTime.getTime() - NOW.getTime();
      return (limitTime = `剩${parseInt(time / (24 * 60 * 60 * 1000))}天`);
    },
    tosearchList() {
      this.$router.push('/searchList')
    },
  },
};
</script>
<style lang='scss' scoped>
.back-white {
  background-color: #fff;
}
.mb-20 {
  margin-bottom: 1.25rem;
}
.width-wrap {
  width: 75rem;
  margin: 0 auto;
}
.border-b-big-black {
  border-bottom: 0.25rem solid #0d1c28;
}
.zq-box-header {
  height: 2.875rem;
  .zq-box-header--middle {
    font-size: 1.5rem;
    .zq-box-header-more {
      font-size: 0.875rem;
    }
  }
  .zq-box-header-title {
    font-size: 1.5rem;
    color: #0d1c28;
    line-height: 2.875rem;
    height: 2.875rem;
  }
  .zq-box-header-more {
    width: 3.75rem;
    cursor: pointer;
    font-size: 0.875rem;
    color: #0d1c28;
    line-height: 1.375rem;
  }
}
.position-relative {
  position: relative;
}
.font-w-6 {
  font-weight: 600;
}
.cursor-pointer {
  cursor: pointer;
}
.home-declare .home-declare-list {
  padding-top: 1.875rem;
}
.flex-between,
.flex-evenly,
.flex-left,
.flex-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -moz-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-left {
  -webkit-box-pack: start;
  -moz-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.theme-type-card {
  cursor: pointer;
  .theme-type-item {
    width: 100%;
    margin-bottom: 1.25rem;
    padding: 1.25rem;
    -webkit-box-shadow: 0 0.125rem 0.5625rem 0.125rem rgba(225, 222, 222, 0.5);
    box-shadow: 0 0.125rem 0.5625rem 0.125rem rgba(225, 222, 222, 0.5);
    .item-main {
      height: 2.8125rem;
    }
    .text {
      font-size: 0.875rem;
      color: #9aa0a3;
      :first-child {
        width: 121px;
      }
    }
    .item-status {
      padding: 1.25rem 0;
      .count-wrap {
        display: inline-block;
        line-height: 1.0625rem;
      }
      .fc-money-limit {
        font-size: 0.8125rem;
        color: #2263e1;
        padding: 0.15625rem 0.375rem;
        border: 0.0625rem solid #2263e1;
        margin-left: 0.625rem;
        line-height: 1.0625rem;
      }
      .time {
        font-size: 0.8125rem;
        color: #fd3938;
        padding: 0.15625rem 0.375rem;
        border: 0.0625rem solid #fd3938;
      }
    }
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ellipsis-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical /*! autoprefixer: off */;
}
.flex-between,
.flex-evenly {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  -moz-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-between {
  -moz-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.mr-5 {
  margin-right: 0.3125rem;
}
img {
  vertical-align: middle;
}
.whiteColor {
  color: #fff;
}
</style>